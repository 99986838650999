.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #272E35;
    /*height: fit-content !important;*/
}

.react-calendar__viewContainer {
    /*height: fit-content !important;*/
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 32px;
    margin-bottom: 4px;
}

.react-calendar__navigation button {
    min-width: 32px !important;
    height: 32px !important;
}

.react-calendar__navigation__prev-button {
    background-color: white !important;

}

.react-calendar__navigation__prev-button:disabled {
    background-color: white !important;
    color: #7E8B99 !important;
}

.react-calendar__navigation button:nth-child(3) {
    pointer-events: none;
    order: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-left: 8px;
}

.react-calendar__navigation button:nth-child(3):first-letter {
    text-transform: capitalize;
}

.react-calendar__navigation button:nth-child(1) {
    display: none;
    order: 4;
}

.react-calendar__navigation button:nth-child(5) {
    display: none;
    order: 5;
}

.react-calendar__navigation button:nth-child(2) {
    order: 2;


}

.react-calendar__navigation button:nth-child(4) {
    order: 3;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #7E8B99;
    border-bottom: #D0D6E0 solid 1px;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none;
    -webkit-text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

/*.react-calendar__month-view__days__day--weekend {*/
/*    color: #d10000;*/
/*}*/

.react-calendar__month-view__days__day--neighboringMonth {
    color: #7E8B99;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    border-radius: 12px;
}

.react-calendar__tile:hover {
    background: #1874D0;
}

.react-calendar__tile:disabled {
    background-color: white;
    color: #7E8B99
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #1874D0;
    color: white
}

.react-calendar__tile--now {
    background: white;
    color: #1874D0
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #1874D0;
    color: white
}

.react-calendar__tile--hasActive {
    background: white;
    color: #1874D0
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #1874D0;
    color: white
}

.react-calendar__tile--active {
    background: #EAFAFF;
    color: #272E35;
    border-radius: 0;
}

.single-mode .react-calendar__tile--active {
    background-color: white;
    color: #272E35;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1874D0;
}

.single-mode .react-calendar__tile--active:enabled:hover,
.single-mode .react-calendar__tile--active:enabled:focus {
    border-radius: 12px;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #F0F9FB;
    color: #272E35;
}

.single-mode .react-calendar__tile--hover {
    background-color: white;
    color: #272E35;
}

.react-calendar__tile--rangeEnd {
    background-color: #2196F3 !important;
    color: white;
    border-radius: 0 12px 12px 0;
}

.single-mode .react-calendar__tile--rangeEnd {
    background-color: #2196F3 !important;
    color: white;
    border-radius: 12px;

}

.react-calendar__tile--rangeEnd:hover {
    background-color: #1874D0 !important;
    color: white;
}

.react-calendar__tile--rangeStart {
    background-color: #2196F3;
    color: white;
    border-radius: 12px 0 0 12px;
}

.single-mode .react-calendar__tile--rangeStart {
    background-color: #2196F3;
    color: white;
    border-radius: 12px;
}

.react-calendar__tile--rangeStart:hover {
    background-color: #1874D0;
    color: white;
}